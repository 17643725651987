import toast from "react-hot-toast";
import { ColumnDef } from "@tanstack/react-table";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { FormEvent, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CheckboxElement, Table, TextInput } from "~/components";
import { useForm } from "~/hooks";
import { hasEmptyValues } from "~/utils/helpers";
import { getPlan, getFeatures } from "~/queries/billingsQueries";
import {
  setPlanFeatures,
  updatePlanDetails,
} from "~/mutations/billingsMutations";
import Loader from "~/components/UI/Loader";

interface Processor {
  processor: string;
  amount: number;
  currency: string;
}

interface FormData {
  name: string;
  description: string;
  interval: string;
  features: string[];
  processors: Processor[];
}

export const EditSubscriptionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const qc = useQueryClient();

  const [selected, setSelected] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const initialFormData: FormData = {
    name: "",
    description: "",
    interval: "",
    features: [],
    processors: [
      { processor: "paystack", amount: 0, currency: "NGN" },
      { processor: "stripe", amount: 0, currency: "USD" },
    ],
  };

  const {
    formData: primary,
    update,
    errors,
    setData,
  } = useForm<FormData>({ initial: initialFormData });

  const {
    data: plan,
    isLoading: isPlanLoading,
    error: planError,
  } = useQuery({
    queryKey: ["plan", { id }],
    queryFn: () =>
      getPlan(id).catch((err) => {
        toast.error(err.message);
        throw err;
      }),
  });

  const {
    data: featuresData,
    isLoading: isFeaturesLoading,
    error: featuresError,
  } = useQuery({
    queryKey: ["features"],
    queryFn: () =>
      getFeatures().catch((err) => {
        toast.error(err.message);
        throw err;
      }),
  });

  useEffect(() => {
    if (plan) {
      const newFormData: FormData = {
        ...initialFormData,
        ...plan,
        features: [],
      };

      // Update setData directly with newFormData if different
      if (JSON.stringify(primary) !== JSON.stringify(newFormData)) {
        setData(newFormData);
      }

      const featureIds = plan.features.map((f: any) => f.id);
      setSelectedFeatures((prev) =>
        JSON.stringify(prev) !== JSON.stringify(featureIds) ? featureIds : prev
      );

      const newInterval = [plan.interval];
      setSelected((prev) =>
        JSON.stringify(prev) !== JSON.stringify(newInterval)
          ? newInterval
          : prev
      );
    }
  }, [plan]); // Remove setData from dependency array

  const { mutate: mutateFeatures } = useMutation({
    mutationFn: () => setPlanFeatures(id, { features: selectedFeatures }),
    onSuccess: () => {
      toast.success("Features updated successfully");
      qc.invalidateQueries({ queryKey: ["plan", { id }] });
    },
  });

  const { mutate: mutateDetails } = useMutation({
    mutationFn: () =>
      updatePlanDetails(id, {
        name: primary.name,
        description: primary.description,
        interval: selected[0],
      }),
    onSuccess: () => {
      toast.success("Plan details updated successfully");
      qc.invalidateQueries({ queryKey: ["plan", { id }] });
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsEditing(true);
      await Promise.all([mutateFeatures(), mutateDetails()]);
      navigate(`/app/subscriptions/${id}`);
    } catch (error: any) {
      toast.error(`Error updating plan: ${error.message}`);
    } finally {
      setIsEditing(false);
    }
  };

  const handleFeatureSelection = useCallback((featureId: string) => {
    setSelectedFeatures((prev) =>
      prev.includes(featureId)
        ? prev.filter((id) => id !== featureId)
        : [...prev, featureId]
    );
  }, []);

  const isFormValid =
    !hasEmptyValues(primary) &&
    selectedFeatures.length > 0 &&
    selected.length > 0;

  const columns: ColumnDef<any>[] = [
    {
      id: "feature",
      cell: ({ row }) => row.original.name,
      header: () => <span>Feature</span>,
      size: 80,
    },
    {
      id: "select",
      cell: ({ row }) => (
        <CheckboxElement
          checked={selectedFeatures.includes(row.original.id)}
          name={row.original.id}
          changeFunction={() => handleFeatureSelection(row.original.id)}
        />
      ),
      header: () => <span>Select</span>,
      size: 20,
    },
  ];

  if (isPlanLoading || isFeaturesLoading) return <Loader />;
  if (planError || featuresError)
    return (
      <div>Error loading data: {(planError || featuresError)?.message}</div>
    );

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full text-[#0A0D14] overflow-clip px-8 pt-10 pb-11 h-full"
    >
      <div className="flex items-center w-full justify-between">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#0A0D14] font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Edit subscription plan</span>
        </button>
        <div className="flex items-center gap-x-2">
          <Button mono label="Cancel" effect={() => navigate(-1)} />
          <Button
            label="Save Changes"
            disabled={!isFormValid || isEditing}
            type="submit"
          />
        </div>
      </div>

      <div className="flex flex-col items-center w-full overflow-scroll">
        <div className="flex flex-col gap-y-8 w-full items-center mt-6 max-w-[768px] mx-auto">
          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Plan details</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              <div className="grid grid-cols-2 gap-x-5">
                <TextInput
                  name="name"
                  label="Plan Name"
                  required
                  value={primary.name}
                  placeholder="Enter plan name"
                  error={errors.name}
                  handleInputChange={(value) => update(value)} // Pass only the value
                />
                <TextInput
                  name="description"
                  label="Description"
                  required
                  value={primary.description}
                  placeholder="Enter plan description"
                  error={errors.description}
                  handleInputChange={(value) => update(value)} // Pass only the value
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label className="text-sm font-medium text-[#0A0D14]">
                  Billing Interval
                </label>
                <div className="flex items-center gap-x-2">
                  <CheckboxElement
                    label="Monthly"
                    checked={selected.includes("monthly")}
                    name="monthly"
                    changeFunction={() => setSelected(["monthly"])}
                  />
                  <CheckboxElement
                    label="Yearly"
                    checked={selected.includes("yearly")}
                    name="yearly"
                    changeFunction={() => setSelected(["yearly"])}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col gap-y-5 w-full"> */}
          {/* <h3 className="font-medium">Payment Processors</h3> */}
          {/* Paystack and Stripe processor details */}
          {/* </div> */}

          <div className="flex flex-col gap-y-5 w-full">
            <h3 className="font-medium">Features</h3>
            <div className="p-5 rounded-lg bg-white border flex flex-col w-full gap-y-5">
              {featuresData?.data ? (
                <Table
                  key="features"
                  cols={columns}
                  rawData={featuresData.data}
                />
              ) : (
                <div>No features available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
