import React from "react";
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getReports, getReportsStats, updateProjectReport } from "~/api";
import {
  Button,
  Header,
  Kebab,
  Pagination,
  Pill,
  SearchBar,
  TextPanes,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { usePagination, usePanes } from "~/hooks";
import { isActionAllowed } from "~/utils/authHelper";
import { getDateandTime, getFormattedDate } from "~/utils/dateUtils";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { DeleteReport, SendEmail, CancelReportSchedule } from "~/actions";
import { DuplicateReport } from "~/actions/Projects/DuplicateReport";
import { openModal } from "~/providers";
// import { getProjectUsers } from "~/queries/projectQueries";

interface TableProps {
  cols: readonly ColumnDef<any>[];
  rawData: any[];
  clickFunction?: (rowData: any) => void;
  linkPrefix?: string;
  linkSuffix?: string;
  extra?: string;
  loading?: boolean;
  useData?: boolean;
  truncate?: boolean;
}

export const ReportsPage = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const active = searchParams.get("active") || "";
  const search = searchParams.get("search") || "";

  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { show, handlePaneSwitch } = usePanes(active);
  const popup = openModal();

  const {
    data: reports,
    isLoading,
    refetch,
  } = useQuery<ListResponse<ProjectReport>>({
    queryKey: ["reports", { page, limit, search, show }],
    queryFn: async () => {
      try {
        const data = await getReports({
          page,
          limit,
          "filter[status]": show,
          "filter[title]": search,
        });

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  console.log("REPORT>>>>>>", reports);

  const { data: reportStats } = useQuery({
    queryKey: ["reports", "stats"],
    queryFn: async () => {
      try {
        const data = await getReportsStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  // const { data: users } = useQuery<User[]>({
  //   queryKey: ["project-users", "list", id],
  //   queryFn: async () => {
  //     try {
  //       const data = await getProjectUsers(id);
  //       return data;
  //     } catch (error: any) {
  //       toast.error(error?.message);
  //     }
  //   },
  // });

  const { mutate: submitReport } = useMutation({
    mutationFn: ({
      projectId,
      reportId,
      status,
    }: {
      projectId: string;
      reportId: string;
      status: string;
    }) => updateProjectReport(projectId, reportId, { status }),
    onSuccess: (_, { status }) => {
      refetch();
      if (status === "approved" || status === "declined") {
        toast.success(`Report ${status} successfully`);
      } else {
        toast.success("Report updated successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const getTotalCount = (array: any[]) => {
    const validValues = ["draft", "pending", "approved", "declined"];

    return array?.reduce((total, obj) => {
      if (validValues.includes(obj?.value)) {
        return total + parseInt(obj?.count, 10);
      }
      return total;
    }, 0);
  };

  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(reportStats),
    },
    ...(reportStats?.map((stat: any) => ({
      id: stat.value,
      label: stat.value?.replace(/\b\w/g, (char: string) => char.toUpperCase()),
      show: true,
      value: stat.count,
    })) || []),
  ];

  const colums: ColumnDef<ProjectReport>[] = [
    {
      accessorFn: (row) => row.title,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Report Name</span>,
      size: 26,
    },
    {
      accessorFn: (row) => row.title,
      id: "name",
      cell: ({ row }) => row.original.project?.name,
      header: () => <span>Project Name</span>,
      size: 26,
    },
    {
      id: "startDate",
      cell: ({ row }) => (
        <span>{getFormattedDate(row.original.startDate)}</span>
      ),
      header: () => <span>From</span>,
      size: 13,
    },
    {
      id: "endDate",
      cell: ({ row }) => <span>{getFormattedDate(row.original.endDate)}</span>,
      header: () => <span>To</span>,
      size: 13,
    },

    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 17,
    },
    {
      id: "sentAt",
      cell: ({ row }) => <span>{getDateandTime(row.original.sentAt)}</span>,
      header: () => <span>Sent At</span>,
      // size: 11,
    },
    {
      id: "event",
      cell: ({ row }) => (
        <Kebab>
          <div className="flex flex-col w-[150px]">
            {isActionAllowed("add-project-report") && (
              <button
                onClick={() =>
                  popup({
                    component: <DuplicateReport report={row.original} />,
                  })
                }
                className="kebab-button"
              >
                Duplicate
              </button>
            )}

            <button
              onClick={() =>
                navigate(
                  `/app/projects/${row.original.projectId}/reports/${row.original.id}`
                )
              }
              className="kebab-button"
            >
              View
            </button>

            {row.original.status === "pending" &&
              isActionAllowed("approve-project-report") && (
                <button
                  className="kebab-button"
                  onClick={() =>
                    submitReport({
                      projectId: row.original.projectId,
                      reportId: row.original.id,
                      status: "approved",
                    })
                  }
                >
                  Approve
                </button>
              )}
            {row.original.status === "pending" &&
              isActionAllowed("decline-project-report") && (
                <button
                  onClick={() =>
                    submitReport({
                      projectId: row.original.projectId,
                      reportId: row.original.id,
                      status: "declined",
                    })
                  }
                  className="kebab-button"
                >
                  Reject
                </button>
              )}
            {row.original.status === "scheduled" && (
              <button
                onClick={() =>
                  handleCancel(
                    row.original.id,
                    row.original.title,
                    row.original
                  )
                }
                className="kebab-button"
                style={{ color: "#DF1C41" }}
              >
                Cancel
              </button>
            )}
            {(row.original.status === "approved" ||
              row.original.status === "draft") &&
              isActionAllowed("delete-project-report") && (
                <button
                  onClick={() => handleDelete(row.original)}
                  className="kebab-button"
                  style={{ color: "#DF1C41" }}
                >
                  Delete
                </button>
              )}

            {row.original.status === "scheduled" &&
              isActionAllowed("cancel-project-report") && (
                <button
                  onClick={() =>
                    submitReport({
                      projectId: row.original.projectId,
                      reportId: row.original.id,
                      status: "cancelled",
                    })
                  }
                  className="kebab-button"
                >
                  Cancel
                </button>
              )}
            {(row.original.status === "sent" ||
              row.original.status === "approved") &&
              isActionAllowed("export-project-report") && (
                <button
                  onClick={() =>
                    popup({
                      component: (
                        <SendEmail
                          reportId={row.original.id}
                          projectId={row.original.projectId!}
                        />
                      ),
                    })
                  }
                  className="kebab-button"
                >
                  {row.original.status === "sent" ? "Resend" : "Send"}
                </button>
              )}
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
      // size: 16,
    },
  ];

  const handleCancel = (reportId: string, name: string, report: any) => {
    popup({
      component: (
        <CancelReportSchedule
          id={report.projectId}
          reportId={reportId}
          name={name}
        />
      ),
    });
  };

  const handleClick = (data: ProjectReport) => {
    navigate(`/app/projects/${data.projectId}/reports/${data.id}/preview`);
  };

  const handleDelete = (report: ProjectReport) => {
    popup({
      component: (
        <DeleteReport
          id={report.projectId}
          reportId={report.id}
          name={report.title}
        />
      ),
    });
  };

  const searchFunction = (v: string) => {
    setSearchParams(() => ({
      search: v!,
      show: show!,
      page: "1",
      limit: "10",
    }));
    close?.();
  };
  const clearFunction = () => {
    setSearchParams(new URLSearchParams());
  };
  return (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex items-center justify-between">
        <Header title="Reports" subtext="Manage all your reports." />
        {isActionAllowed("add-project-report") && (
          <Button
            label="Create a report"
            effect={() => navigate("create")}
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                  fill="white"
                />
              </svg>
            }
            leftIcon
          />
        )}
      </div>
      <div className="flex flex-col mt-6 w-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5  overflow-x-scroll min-h-[400px]">
          <div className="flex items-center gap-x-2">
            {" "}
            <SearchBar
              placeholder="Search..."
              initialValue={search}
              onClear={clearFunction}
              onSearch={searchFunction}
            />
          </div>
          {isLoading ? (
            <Loader big />
          ) : (reports?.data?.length || 0) > 0 ? (
            <Table
              clickFunction={handleClick}
              useData
              key="reports"
              cols={colums}
              rawData={reports?.data || []}
            />
          ) : (
            <div className="  w-full h-full min-h-[400px] rounded-xl flex flex-col items-center justify-center text-center">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No {show} reports to show
                  </span>
                  {isActionAllowed("add-project-report") && (
                    <span className=" text-sm text-[#525866]">
                      You have not added any new projects. Create one to get
                      started
                    </span>
                  )}
                </div>
                {isActionAllowed("add-project-report") && (
                  <Button
                    mono
                    effect={() => navigate("create")}
                    label="Create a report"
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                          fill="black"
                        />
                      </svg>
                    }
                    leftIcon
                    // effect={() => popup({ component: <AddRole /> })}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {(reports?.meta?.totalNumberOfRecords || 0) > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={reports?.meta?.totalNumberOfPages || 1}
            total={reports?.meta?.totalNumberOfRecords || 0}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export const Table = ({
  cols,
  rawData,
  clickFunction,
  linkPrefix,
  linkSuffix,
  extra,
  useData,
}: TableProps) => {
  const columns: ColumnDef<any, any>[] = [...cols];
  const data = React.useMemo(() => [...rawData], [rawData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full overflow-x-auto h-full">
      <table className="w-full min-w-[640px]">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="bg-[#F6F8FA] text-sm text-[#525866] font-normal py-2 px-3 text-left first:rounded-l-lg last:rounded-r-lg"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={`border-b last:border-none text-[#0A0D14] transition-all duration-200 ${
                clickFunction ? "hover:bg-[#f5f5f5] hover:cursor-pointer" : ""
              }`}
              onClick={() =>
                clickFunction
                  ? useData
                    ? clickFunction(row.original)
                    : clickFunction(
                        `${linkPrefix ? linkPrefix : ""}${
                          row.original.clientId || row.original.id
                        }${linkSuffix ? linkSuffix : ""}${
                          extra ? row.original[extra] : ""
                        }`
                      )
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-[14px] px-3 text-sm">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
