import { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import { motion } from "framer-motion";

interface EmailCountdownProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const EmailCountdown = ({
  onCancel,
  onConfirm,
}: EmailCountdownProps) => {
  const [countdown, setCountdown] = useState(10);
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    if (isCancelled) return;

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isCancelled]);

  useEffect(() => {
    if (countdown === 0 && !isCancelled) {
      setIsCancelled(true);
      onConfirm();
    }
  }, [countdown, isCancelled, onConfirm]);

  const handleCancel = useCallback(() => {
    setIsCancelled(true);
    setCountdown(0);
    toast.error("Email sending cancelled");
    onCancel();
  }, [onCancel]);

  return (
    <motion.div
      initial={{ x: "-100%", y: "100%", opacity: 0 }}
      animate={{ x: 0, y: 0, opacity: 1 }}
      exit={{ x: "-100%", opacity: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      className="fixed bottom-4 left-4 w-64 flex flex-col items-center gap-3 border border-gray-300 p-4 rounded-lg shadow-lg bg-white z-50"
    >
      <p className="text-gray-700">
        Email will be sent in {countdown} seconds...
      </p>
      <button
        onClick={handleCancel}
        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Cancel
      </button>
    </motion.div>
  );
};
