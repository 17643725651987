/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ColumnDef } from "@tanstack/react-table";
import { TableAvatar } from "~/components/UI/TableAvatar";
import {
  ActivateSubscription,
  DeactivateSubscription,
  DeleteSubscription,
} from "~/actions";
import { usePagination } from "~/hooks";
import { ExpandableButton, Group, Pill, Table, Pagination } from "~/components";
import Loader from "~/components/UI/Loader";
import { openModal } from "~/providers";
import { adminGetWorkspaces } from "~/queries/workspaceQueries";
import { getPlan } from "~/queries/billingsQueries";
import { totoPascalCase } from "~/utils/helpers";
import { getFormattedDate } from "~/utils/dateUtils";

export const SubscriptionPage = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { id } = useParams();
  const popup = openModal();

  // let isLoading = false;

  const { data: plan } = useQuery<Plan>({
    queryKey: ["plan", { page, limit, id }],
    queryFn: async () => {
      try {
        const data = await getPlan(id);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  // const isPlanLoading = !plan; // Add this line to determine loading state

  const { data: workspaces, isLoading } = useQuery({
    queryKey: ["workspaces", { page, limit, id }],
    queryFn: async () => {
      try {
        const data = await adminGetWorkspaces({
          page,
          limit,
          "filter[planId]": id,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const subscribers = workspaces?.data || [];

  const handleUpdate = (action: "active" | "deactivate" | "delete") => {
    if (action == "active")
      return popup({ component: <ActivateSubscription data={subscription} /> });
    if (action == "deactivate")
      return popup({
        component: <DeactivateSubscription data={subscription} />,
      });
    if (action == "delete")
      return popup({ component: <DeleteSubscription data={subscription} /> });
  };
  const subscription = {
    name: plan?.name,
    id,
    status: plan?.status,
    plan: plan?.interval,
    // cost: "₦ 25,000 NGN",
    cost: plan ? plan.processors : [],
    features: plan ? plan.features : [], // Ensure features is an array or valid value
    billingOptions: "",
  };
  const details = [
    { title: "Plan", value: totoPascalCase(plan?.name) },
    { title: "Description", value: totoPascalCase(plan?.description) },
    {
      title: "Cost",
      value: plan?.processors
        ? plan.processors.map((p) => `${p.amount} ${p.currency}`).join(", ")
        : "N/A",
    },
    {
      title: "Features",
      value: (
        <div className="flex flex-col gap-y-1 mt-2">
          {subscription?.features?.map(
            (
              feature: {
                id: string;
                name: string;
                slug: string;
                description: string;
                createdAt: string;
              },
              index: number
            ) => (
              <span className="text-sm" key={index}>
                <span className="text-[#868C98]">{feature.name}</span>
              </span>
            )
          ) || <span>No features available</span>}
        </div>
      ),
    },
    {
      title: "Billing Interval",
      value: totoPascalCase(plan?.interval),
    },
  ];

  const colums: ColumnDef<(typeof workspaces.data)[0]>[] = [
    {
      id: "workspace-name",
      cell: ({ row }) => {
        const name = row.original.name || "Unknown";
        const [first_name, last_name] = name.split(" ");
        return (
          <TableAvatar
            first_name={totoPascalCase(first_name)}
            last_name={totoPascalCase(last_name)}
          />
        );
      },
      header: () => <span>Name</span>,
      size: 8,
    },
    {
      id: "created-by",
      cell: ({ row }) => {
        const { firstName, lastName } =
          row.original?.primaryUser?.profile || {};
        return (
          <TableAvatar
            first_name={totoPascalCase(firstName || row.original.name)}
            last_name={totoPascalCase(lastName || row.original.name)}
          />
        );
      },
      header: () => <span>Created By</span>,
      size: 8,
    },
    {
      id: "email",
      cell: ({ row }) => <span>{row.original.primaryUser?.email}</span>,
      header: () => <span>Email Address</span>,
      size: 28,
    },
    {
      id: "date",
      cell: ({ row }) => (
        <div className="flex items-center gap-x-2">
          {getFormattedDate(row.original.createdAt)}
        </div>
      ),
      header: () => <span>Date Created</span>,
      size: 15,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      // size: 9.9,
    },
  ];

  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Subscription Plans</span>
        </button>
      </div>
      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#0A0D14] font-medium text-lg">
                Subscription Plans / {plan?.name}
              </h1>
              <Pill status={subscription.status} />
            </div>
            <span className="text-sm text-[#525866]">
              Plan ID: {subscription?.id?.substring(0, 6)}
            </span>
          </div>
          <ExpandableButton label="Actions" mono>
            <div className="min-w-[144px] w-full flex flex-col items-start">
              <button onClick={() => navigate("edit")} className="kebab-button">
                Edit
              </button>
              {subscription.status == "active" && (
                <button
                  onClick={() => handleUpdate("deactivate")}
                  className="kebab-button"
                >
                  Deactivate
                </button>
              )}
              {subscription.status !== "active" && (
                <button
                  onClick={() => handleUpdate("active")}
                  className="kebab-button"
                >
                  Activate
                </button>
              )}
              <button
                style={{ color: "#DF1C41" }}
                onClick={() => handleUpdate("delete")}
                className="kebab-button"
              >
                Delete
              </button>
            </div>
          </ExpandableButton>
        </div>
      </Group>
      <div className="flex mt-6 items-center gap-x-5 w-full h-full">
        <Group key="left">
          <div className="w-[35%] h-full">
            <Group key="details">
              <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm ">
                <header className="w-full flex items-center justify-between border-b pb-4">
                  <span className="font-medium">Subscription Information</span>
                </header>
                <div className="py-4 flex flex-col gap-y-4">
                  {details.map((d) => (
                    <div key={d.title} className="flex flex-col ">
                      <div className="text-xs text-[#525866]">{d.title}</div>
                      <div className="text-sm text-[#0A0D14]">{d.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Group>
          </div>
        </Group>
        <Group key="right">
          <div className="w-full h-full">
            <h3 className="font-medium">Subscribers</h3>
            {isLoading ? (
              <Loader big />
            ) : subscribers.length > 0 ? (
              <div className="mt-4 bg-white w-full rounded-xl flex-col items-center justify-center ">
                <Table
                  // clickFunction={navigate}
                  key="subscribers"
                  cols={colums}
                  rawData={subscribers}
                />
              </div>
            ) : (
              <div className="mt-4 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center ">
                <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5  text-center">
                  <img src="/empty.svg" className="w-[100px]" />
                  <div className="flex flex-col items-center gap-y-2">
                    <span className=" text-sm text-[#0A0D14]">
                      No subscribers to show
                    </span>
                    <span className=" text-sm text-[#525866]">
                      This plan currently does not have any subscribers
                    </span>
                  </div>
                </div>
              </div>
            )}
            {workspaces?.meta?.totalNumberOfRecords > 10 && (
              <Pagination
                page={page}
                limit={limit}
                pages={workspaces?.meta?.totalNumberOfPages}
                total={workspaces?.meta?.totalNumberOfRecords}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </Group>
      </div>
    </div>
  );
};
