import { Choice } from "~/actions/Settings/settings/setting/plans/components/Choice";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlans } from "~/queries/billingsQueries";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { PublicPlanCard } from "./PublicPlansCard";
import { verifySubscription } from "~/mutations/billingsMutations";
import { useLocation } from "~/hooks";
import { useAuth } from "~/providers";

export const PublicPlansPage = () => {
  const { workspace } = useAuth();
  const [choice, setChoice] = useState("Monthly");
  const [reference, setReference] = useState<any>();
  const { location, finding } = useLocation();

  const { data: plans, isLoading } = useQuery<ListResponse<Plan>>({
    queryKey: ["plans", "active", choice], // Added choice to queryKey to trigger refetch
    queryFn: async () => {
      try {
        const data = await getPlans({
          "filter[status]": "active",
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
        return { data: [] }; // Return empty array to prevent undefined errors
      }
    },
  });

  const { isLoading: verifying } = useQuery({
    enabled: Boolean(reference?.reference),
    queryKey: ["verification"],
    queryFn: async () => {
      try {
        const data = await verifySubscription(
          reference?.id,
          reference?.reference
        );
        window.location.assign("/app");
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  return (
    <div className="w-full flex flex-col gap-y-6 relative h-full overflow-x-scroll lg:overflow-auto items-center pt-14">
      <h1 className="text-[#0A0D14] text-[32px] font-medium">
        The ultimate delivery solution, at the best value...
      </h1>
      {/* <Header title="Plans" subtext="Do more by upgrading to a paid plan." /> */}
      <div className="pt-">
        <div className="flex justify-center">
          <Choice choice={choice} setChoice={setChoice} />
        </div>
        {isLoading || finding ? (
          <Loader big />
        ) : (
          <div className="flex flex-col lg:flex-row items-center lg:items-start h-full gap-8">
            {plans?.data
              .filter(
                (plan) => plan.interval.toLowerCase() === choice.toLowerCase()
              )
              .map((plan, index) => (
                <div className="pt-10" key={plan?.id}>
                  <PublicPlanCard
                    index={index}
                    setReference={setReference}
                    location={location!}
                    current={workspace?.subscription}
                    data={plan}
                    time={choice === "Monthly" ? "month" : "year"}
                  />
                </div>
              ))}
          </div>
        )}
        {verifying && (
          <div className="absolute inset-0 w-full h-full">
            <Loader big />
          </div>
        )}
      </div>
    </div>
  );
};
