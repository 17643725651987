/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";
import {
  createParser,
  type ParsedEvent,
  type ReconnectInterval,
} from "eventsource-parser";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl = import.meta.env.VITE_BASE_URL;

export const getChats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/chats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getChatSuggestions = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/chats/suggestions`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getChatSessions = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/chats/sessions`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const askChat = async (
  data: any,
  onMessage: (message: ParsedEvent) => void
) => {
  const ids: Record<string, boolean> = {};
  function onParse(event: ParsedEvent | ReconnectInterval) {
    if (event.type === "event") {
      if (!ids[event.id!]) {
        ids[event.id!] = true;
        onMessage(event);
      }
    } else if (event.type === "reconnect-interval") {
      console.log(
        "We should set reconnect interval to %d milliseconds",
        event.value
      );
    }
  }
  const parser = createParser(onParse);
  try {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/chats/assistant`,
      headers: {
        ...headers,
        Accept: "*/*",
        "X-Referrer": window.location.href,
      },
      data,
      onDownloadProgress(progressEvent) {
        const xhr = progressEvent.event.target;
        const { responseText } = xhr;
        parser.feed(responseText);
      },

      withCredentials: true,
      withXSRFToken: true,
    });
    return response;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  } finally {
    parser.reset();
  }
};
