/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectReport, updateProjectReport } from "~/api";
import {
  Button,
  ExpandableButton,
  Group,
  MultiSelect,
  TextArea,
  TextInput,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm } from "~/hooks";
import { getProject } from "~/queries/projectQueries";

import dayjs from "dayjs";
import { ContentEditor, Panes } from "./components";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "~/constants/animations";
import Handlebars from "handlebars";
// @ts-ignore
import HandlebarsUtils from "handlebars-utils";
import React from "react";
import { useProjectUsers } from "~/hooks/useLists";
import { handleError } from "~/utils/helpers";
import { v4 } from "uuid";
import { useDebounce } from "use-debounce";

Handlebars.registerHelper("eq", function (a, b, options) {
  if (arguments.length === 2) {
    options = b;
    b = options.hash.compare;
  }
  // @ts-ignore
  return HandlebarsUtils.value(a === b, this, options);
});

Handlebars.registerHelper("replace", function (str, a, b) {
  if (!HandlebarsUtils.isString(str)) return "";
  if (!HandlebarsUtils.isString(a)) return str;
  if (!HandlebarsUtils.isString(b)) b = "";
  return str.split(a).join(b);
});

Handlebars.registerHelper("sentence", function (str) {
  if (!HandlebarsUtils.isString(str)) return "";
  return str.replace(/((?:\S[^\.\?\!]*)[\.\?\!]*)/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

export const EditReportPage = () => {
  const { id, reportId } = useParams();
  const navigate = useNavigate();
  const { list = [] } = useProjectUsers(id);
  const [active, setActive] = useState("details");
  const [reviewers, setReviewwers] = useState([]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [sections, setSections] = useState<any>([]);

  const updateSection = (index: number, newSection: any) => {
    const newSections = [...sections];
    newSections[index] = newSection;
    setSections(newSections);
  };

  const addNewSection = () => {
    setSections([
      ...sections,
      {
        key: v4(),
        title: "New Section",
        titleBackgroundColor: "#2D9F75",
        titleTextColor: "#ffffff",
        bodyBackgroundColor: "#EFFAF6",
        bodyTextColor: "#0E121B",
        content: "",
      },
    ]);
  };
  const removeSection = (index: number) => {
    delete sections[index];
    setSections(sections.filter(Boolean));
  };
  const { formData, update, setData, setErrors, errors } = useForm({
    initial: {
      title: "",
      startDate: "",
      endDate: "",
      header: "",
      footer: "",
    },
  });
  const { data: project, isLoading } = useQuery<Project>({
    queryKey: ["project", id],
    queryFn: async () => {
      try {
        const data = await getProject(id);

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { data: report, isLoading: templateLoading } = useQuery<ProjectReport>({
    queryKey: ["report", reportId],
    queryFn: async () => {
      try {
        const data = await getProjectReport(id, reportId);
        setData({
          title: data.data.title,
          startDate: dayjs(data.data.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(data.data.endDate).format("YYYY-MM-DD"),
          header: data.data.data.header,
          footer: data.data.data.footer,
        });
        setReviewwers(data.data?.reviewers.map((r: any) => r.id));

        setSections(
          data.data.data?.sections
            ? Object.values(data.data.data.sections).map((s: any) => ({
                ...s,
                key: v4(),
              }))
            : []
        );
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!project,
  });

  const template = React.useMemo(() => {
    return Handlebars.compile(report?.reportTemplate?.body || "", {
      noEscape: true,
    });
  }, [report?.reportTemplate?.body]);

  const html = React.useMemo(() => {
    if (!report) return "";
    const html = template({
      ...report?.data,
      ...formData,
      reportTitle: formData.title,
      reportStartDate: formData.startDate
        ? dayjs(formData.startDate).format("LL")
        : "",
      reportEndDate: formData.endDate
        ? dayjs(formData.endDate).format("LL")
        : "",
      startDate: formData.startDate
        ? dayjs(formData.startDate).format("LL")
        : "",
      endDate: formData.endDate ? dayjs(formData.endDate).format("LL") : "",
      sections: sections.map(({ content, ...section }: any) => ({
        content,
        ...section,
      })),
    });
    return html;
  }, [report, sections, formData, template]);

  const [debouncedHtml] = useDebounce(html, 100);

  const { mutate, isPending } = useMutation({
    mutationFn: (status?: string) =>
      updateProjectReport(id, reportId, {
        title: formData.title,
        startDate: formData.startDate
          ? dayjs(formData.startDate!).format("YYYY-MM-DD")
          : formData.startDate,
        endDate: formData.endDate
          ? dayjs(formData.endDate).format("YYYY-MM-DD")
          : formData.endDate,
        reviewers,
        data: {
          ...report?.data,
          header: formData.header,
          footer: formData.footer,
          sections: sections || report?.data?.sections,
        },
        status: status || "draft",
      }),
    ...{
      onSuccess() {
        navigate(`/app/projects/${id}`);
        toast.success("Report updated");
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setActive("details");
          setErrors(handledError.messages);
        }
      },
    },
  });

  useEffect(() => {
    if (iframeRef.current) {
      // Access the iframe document and inject the new content
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow!.document;

      iframeDocument.open();
      iframeDocument.write(debouncedHtml);
      iframeDocument.close();
    }
  }, [debouncedHtml]);

  const panes = [
    {
      id: "details",
      label: "Details",
      show: true,
    },
    { id: "content", label: "Content", show: true },
  ];
  return isLoading || templateLoading ? (
    <Loader big />
  ) : (
    <div className="flex flex-col text-[#0A0D14] oveflow-clip h-full px-8 pt-8  fixed inset-0 w-screen z-30 bg-[#F7F7F7]">
      <div className="flex flex-col gap-4  lg:flex-row items-center w-full justify-between">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#0A0D14] text-lg font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span> {formData?.title}</span>
        </button>
        <div className="flex items-center gap-x-2">
          <Button mono label="Cancel" effect={() => navigate(-1)} />
          {report?.status !== "approved" && (
            <ExpandableButton
              label="Save"
              disabled={!formData.startDate || !formData.endDate}
              loading={isPending}
            >
              <div className="min-w-[168px] w-full flex flex-col items-start">
                <button
                  onClick={() => mutate(undefined)}
                  className="kebab-button"
                >
                  Save as draft
                </button>
                <button
                  onClick={() => mutate("pending")}
                  className="kebab-button"
                >
                  Submit
                </button>
              </div>
            </ExpandableButton>
          )}
        </div>
      </div>
      <div className="flex items-start gap-x-4 h-full mt-6">
        <Group key="left">
          <div className="h-[86%] w-[29%] bg-white border border-[#E2E4E9] overflow-clip pb-10 rounded-t-xl">
            <Panes panes={panes} active={active} handleChange={setActive} />
            <motion.div
              key={active}
              variants={fadeIn}
              animate="animate"
              initial="initial"
              className="p-5 overflow-scroll h-full"
            >
              {active == "details" && (
                <div className="flex flex-col gap-y-5">
                  <TextInput
                    name="title"
                    label="Report name"
                    error={errors.title}
                    value={formData.title}
                    handleInputChange={update}
                  />
                  <TextInput
                    name="startDate"
                    label="Start date"
                    error={errors.startDate}
                    type="date"
                    value={formData.startDate}
                    handleInputChange={update}
                  />
                  <TextInput
                    name="endDate"
                    error={errors.endDate}
                    type="date"
                    label="End Date"
                    value={formData.endDate}
                    handleInputChange={update}
                  />
                  <MultiSelect
                    options={list}
                    setSelected={setReviewwers}
                    selected={reviewers}
                    name="itemId"
                    label={`Reviewers`}
                    placeholder="Select reviewers"
                  />
                  <TextArea
                    value={project?.description}
                    readOnly
                    label="Description"
                    name="description"
                    handleInputChange={update}
                  />
                </div>
              )}
              {active == "content" && (
                <div className="flex flex-col gap-y-5 h-full overflow-scroll">
                  <ContentEditor
                    key="header"
                    section={{
                      key: "header",
                      title: "Header",
                      content: formData.header,
                    }}
                    index={-1}
                    updateSection={(_: any, newSection: any) => {
                      update({
                        target: { name: "header", value: newSection.content },
                      });
                    }}
                    removeSection={() => {}}
                  />
                  <ContentEditor
                    key="footer"
                    section={{
                      key: "footer",
                      title: "Footer",
                      content: formData.footer,
                    }}
                    index={-2}
                    updateSection={(_: any, newSection: any) => {
                      update({
                        target: { name: "footer", value: newSection.content },
                      });
                    }}
                    removeSection={() => {}}
                  />
                  {sections.map((sec: any, index: number) => (
                    <ContentEditor
                      key={sec.key}
                      section={sec}
                      index={index}
                      updateSection={updateSection}
                      removeSection={removeSection}
                    />
                  ))}
                  <Button
                    leftIcon
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                          fill="black"
                        />
                      </svg>
                    }
                    mono
                    label="Add section"
                    effect={addNewSection}
                  />
                </div>
              )}
            </motion.div>
          </div>
        </Group>
        <Group key="template">
          <div className="lg:px-[60px] overflow-scroll h-full w-[68%] report-content">
            <iframe
              title={report?.title}
              // srcDoc={html}
              ref={iframeRef}
              width="100%"
              height="100%"
              style={{
                border: "none",
                overflow: "hidden",
              }}
            ></iframe>
          </div>
        </Group>
      </div>
    </div>
  );
};
