import { Button } from "~/components";
import { getFullMoney } from "~/utils/formatAmount";
// import { openModal } from "~/providers";

interface PublicPlanCardProps {
  index?: number;
  data: Plan;
  time: string;
  current?: Subscription;
  location: UserLocation;
  setReference: (v: any) => void;
}

export const PublicPlanCard = ({
  data,
  time,
  location,
  current = {} as Subscription,
}: //   setReference,
PublicPlanCardProps) => {
  //   const popup = openModal();
  //   const id = data?.id;

  const getProcessor = () => {
    if (!data?.processors?.length) return null;

    const defaultCurrency = location?.currency === "NGN" ? "NGN" : "USD";
    const processorCurrency = current.currency || defaultCurrency;

    return (
      data.processors.find((pro) => pro.currency === processorCurrency) ||
      data.processors[0]
    );
  };

  const processor = getProcessor();

  const handleGetStarted = () => {
    window.location.href = "https://app.wholistic.work/signup";
  };

  if (!data || !processor) return null;

  return (
    <div className="rounded-xl border border-[#E2E4E9] bg-white flex flex-col w-[251px] h-full max-h-full overflow-y-clip">
      <div className="bg-[#F5F7FA] border-b border-[#E2E4E9] px-6 py-4 rounded-t-xl flex flex-col">
        <div className="mt-4 flex flex-col">
          <h3 className="text-[#0E121B] font-medium text-xl">{data.name}</h3>
          <h2 className="mt-3 text-[#35B9E9] font-semibold text-2xl">
            {getFullMoney(
              parseFloat(processor.amount),
              processor.currency,
              location?.languages?.split(",")[0]?.includes("en-NG")
                ? "en-NG"
                : "en-US"
            )}
          </h2>
          <span className="mt-1 text-xs text-[#525866]">per {time}</span>
          {current?.plan?.id === data.id ? (
            <Button
              mono
              disabled
              label="Current Plan"
              {...{ style: { marginTop: 22 } }}
            />
          ) : (
            <Button
              effect={handleGetStarted}
              label="Get Started"
              {...{ style: { marginTop: 22 } }}
            />
          )}
        </div>
      </div>
      <div className="max-h-full h-full flex flex-col p-6 gap-y-5 text-xs text-[#0E121B]">
        {/* Display the description */}
        <div className="text-sm text-[#525866] mb-2">
          {data.description}
        </div>
        <span>All features options:</span>
        <div className="flex flex-col gap-y-5 h-full max-h-full pb-10">
          {data.features?.map((feat) => (
            <div key={feat.id} className="flex items-center gap-x-[6px]">
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 11.625C3.39331 11.625 0.875 9.10669 0.875 6C0.875 2.89331 3.39331 0.375 6.5 0.375C9.60669 0.375 12.125 2.89331 12.125 6C12.125 9.10669 9.60669 11.625 6.5 11.625ZM5.93919 8.25L9.91606 4.27256L9.12069 3.47719L5.93919 6.65925L4.34787 5.06794L3.5525 5.86331L5.93919 8.25Z"
                  fill="#35B9E9"
                />
              </svg>
              <span>{feat.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
