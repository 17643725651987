/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AddProjectReport,
  SendEmail,
  DeleteReport,
  CancelReportSchedule,
} from "~/actions";
import { DuplicateReport } from "~/actions/Projects/DuplicateReport";
import {
  getProjectReports,
  getProjectReportStats,
  updateProjectReport,
} from "~/api";
import {
  Button,
  Kebab,
  Pagination,
  Pill,
  SearchBar,
  SearchDropdown,
  Table,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm, usePagination } from "~/hooks";
import { openModal } from "~/providers";
import { getProjectUsers } from "~/queries/projectQueries";
import { isActionAllowed } from "~/utils/authHelper";
import { getDateandTime, getFormattedDate } from "~/utils/dateUtils";

export const Reports = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const { handlePageChange } = usePagination();

  const popup = openModal();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const filterBy = searchParams.get("filterBy") || "title";

  const { formData: statusFilter, update: statusFilterUpdate } = useForm({
    initial: { status: "" },
  });

  const filters = {
    [`filter[${filterBy}]`]: search || undefined,
    "filter[status]": statusFilter.status || undefined,
  };

  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const {
    data: reports,
    isLoading,
    refetch,
  } = useQuery<ListResponse<ProjectReport>>({
    queryKey: [
      "project",
      id,
      "reports",
      { page, limit, search, ...statusFilter },
    ],
    queryFn: async () => {
      try {
        const data = await getProjectReports(id, {
          page,
          limit,
          ...filters,
        });

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: users } = useQuery<User[]>({
    queryKey: ["project-users", "list", id],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(id);
        return data;
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
  });

  const { data: reportStats } = useQuery({
    queryKey: ["projects", id, "reports", "stats"],
    queryFn: async () => {
      try {
        const data = await getProjectReportStats(id);
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { mutate: submitReport } = useMutation({
    mutationFn: ({ reportId, status }: { reportId: string; status: string }) =>
      updateProjectReport(id, reportId, { status }),
    onSuccess: (_, { status }) => {
      refetch();
      if (status === "approved" || status === "declined") {
        toast.success(`Report ${status} successfully`);
      } else {
        toast.success("Report updated successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // const { mutate: deleteReport } = useMutation({
  //   mutationFn: (reportId: string) => deleteProjectReport(id, reportId),
  //   onSuccess: () => {
  //     refetch();
  //     toast.success("Report deleted successfully");
  //   },
  //   onError: (error) => {
  //     toast.error(error.message);
  //   },
  // });

  const searchFunction = (v: string) => {
    setSearchParams(() => ({
      search: v!,
      page: "1",
      limit: "10",
    }));
  };
  const clearFunction = () => {
    setSearchParams(new URLSearchParams());
  };

  const handleClick = (data: ProjectReport) => {
    navigate(`/app/projects/${id}/reports/${data.id}/preview`);
  };

  const handleDelete = (reportId: string, name: string) => {
    popup({
      component: <DeleteReport id={id} reportId={reportId} name={name} />,
    });
  };

  const handleCancel = (reportId: string, name: string) => {
    popup({
      component: (
        <CancelReportSchedule id={id} reportId={reportId} name={name} />
      ),
    });
  };

  const colums: ColumnDef<ProjectReport>[] = [
    {
      accessorFn: (row) => row.title,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Report Name</span>,
      size: 22,
    },
    {
      id: "email",
      cell: ({ row }) => (
        <span>{getFormattedDate(row.original.startDate)}</span>
      ),
      header: () => <span>From</span>,
      size: 12,
    },
    {
      id: "project",
      cell: ({ row }) => <span>{getFormattedDate(row.original.endDate)}</span>,
      header: () => <span>To</span>,
      size: 16,
    },

    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 14,
    },
    {
      id: "sentAt",
      cell: ({ row }) => <span>{getDateandTime(row.original.sentAt)}</span>,
      header: () => <span>Sent At</span>,
      size: 11,
    },
    {
      id: "event",
      cell: ({ row }) => (
        <Kebab>
          <div className="flex flex-col w-[150px]">
            {isActionAllowed("update-project-report") &&
              !["approved", "sent", "scheduled"].includes(
                row.original.status
              ) && (
                <button
                  onClick={() =>
                    navigate(`/app/projects/${id}/reports/${row.original.id}`)
                  }
                  className="kebab-button"
                >
                  Edit
                </button>
              )}
            {row.original?.status === "pending" &&
              isActionAllowed("approve-project-report") && (
                <button
                  className="kebab-button"
                  onClick={() =>
                    submitReport({
                      reportId: row.original.id,
                      status: "approved",
                    })
                  }
                >
                  Approve
                </button>
              )}
            {row.original?.status === "pending" &&
              isActionAllowed("decline-project-report") && (
                <button
                  onClick={() =>
                    submitReport({
                      reportId: row.original.id,
                      status: "declined",
                    })
                  }
                  className="kebab-button"
                >
                  Reject
                </button>
              )}
            {isActionAllowed("add-project-report") && (
              <button
                onClick={() =>
                  popup({
                    component: <DuplicateReport report={row.original} />,
                  })
                }
                className="kebab-button"
              >
                Duplicate
              </button>
            )}
            {row.original.status === "pending" &&
              isActionAllowed("update-project-report") && (
                <button
                  onClick={() =>
                    submitReport({
                      reportId: row.original.id,
                      status: "pending",
                    })
                  }
                  className="kebab-button"
                >
                  Submit
                </button>
              )}
            {["approved", "sent"].includes(row.original.status) &&
              isActionAllowed("export-project-report") && (
                <button
                  onClick={() =>
                    popup({
                      component: (
                        <SendEmail
                          reportId={row.original.id}
                          projectId={id!}
                          users={users}
                        />
                      ),
                    })
                  }
                  className="kebab-button"
                >
                  {row.original.status === "approved" ? "Send" : "Resend"}
                </button>
              )}
            {row.original.status === "scheduled" && (
              <button
                onClick={() =>
                  handleCancel(row.original.id, row.original.title)
                }
                className="kebab-button"
                style={{ color: "#DF1C41" }}
              >
                Cancel
              </button>
            )}
            {row.original.status === "approved" ||
              (row.original.status === "draft" &&
                isActionAllowed("delete-project-report") && (
                  <button
                    onClick={() =>
                      handleDelete(row.original.id, row.original.title)
                    }
                    className="kebab-button"
                    style={{ color: "#DF1C41" }}
                  >
                    Delete
                  </button>
                ))}
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
      // size: 16,
    },
  ];
  return reports?.data?.length ||
    Object.values(filters).filter(Boolean).length ? (
    <div className="  bg-white w-full h-full rounded-xl flex flex-col min-h-[400px]">
      {isLoading ? (
        <Loader big />
      ) : (
        <div className="p-4 flex flex-col gap-y-5">
          <div className="flex flex-col gap-y-2 lg:flex-row items-center lg:gap-x-2">
            {" "}
            <SearchBar
              placeholder={`Search by ${filterBy}`}
              initialValue={search}
              onClear={clearFunction}
              onSearch={searchFunction}
            />
            <SearchDropdown
              label="Status"
              name="status"
              update={statusFilterUpdate}
              options={reportStats?.map((r: any) => ({
                label: r.value?.replace(/\b\w/g, (char: string) =>
                  char.toUpperCase()
                ),
                value: r.value,
              }))}
              placeholder="All"
              value={statusFilter.status}
            />
            <span className="ml-auto">
              {isActionAllowed("add-project-report") && (
                <Button
                  effect={() =>
                    popup({ component: <AddProjectReport id={id} /> })
                  }
                  label="Create Report"
                  icon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                        fill="white"
                      />
                    </svg>
                  }
                  leftIcon
                />
              )}
            </span>
          </div>
          <div className="overflow-x-scroll lg:overflow-auto">
            <Table
              clickFunction={handleClick}
              useData
              key="projects"
              cols={colums}
              rawData={reports?.data || []}
            />
          </div>
          {(reports?.meta?.totalNumberOfRecords || 0) > 10 && (
            <Pagination
              page={page}
              limit={limit}
              pages={reports?.meta?.totalNumberOfPages || 1}
              total={reports?.meta?.totalNumberOfRecords || 0}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="  bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center min-h-[400px]">
      {isLoading ? (
        <Loader big />
      ) : (
        <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
          <img src="/empty.svg" className="w-[100px]" />
          <div className="flex flex-col items-center gap-y-2">
            <span className=" text-sm text-[#0A0D14]">No reports to show</span>
            {isActionAllowed("add-project-report") && (
              <span className=" text-sm text-[#525866]">
                You have not created any reports for this project
              </span>
            )}
          </div>
          {isActionAllowed("add-project-report") && (
            <Button
              mono
              effect={() => popup({ component: <AddProjectReport id={id} /> })}
              label="Create Report"
              icon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                    fill="black"
                  />
                </svg>
              }
              leftIcon
            />
          )}
        </div>
      )}
    </div>
  );
};
